@use 'styles/base/mixins';
@use 'styles/base/variables';

:local(.ctaButton) {
  border-bottom: variables.$border-black;
  margin-bottom: variables.$spacing-xxl;
  padding-bottom: variables.$spacing-xxxl;

  :global(.button) {
    width: 100%;

    &:global(.button--black) {
      color: variables.$gb-black;

      &:hover {
        color: variables.$gb-white;
      }
    }

    @include mixins.screen-sm {
      width: auto;
    }
  }
}

.disableBorder {
  border: none;
  padding-bottom: 0;
}
