@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

:local(.ctaButton) {
  border-bottom: $border-black;
  margin-bottom: $spacing-xxl;
  padding-bottom: $spacing-xxxl;

  :global(.button) {
    width: 100%;

    &:global(.button--black) {
      color: $gb-black;

      &:hover {
        color: $gb-white;
      }
    }

    @include screen-sm {
      width: auto;
    }
  }
}

.disableBorder {
  border: none;
  padding-bottom: 0;
}
